import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Button, Heading } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import MetaTitle from 'ui/meta-title';
import { useAccount } from 'frontastic';
import styles from './session-timeout.module.scss';
import { useMemo } from 'react';
import { UNPROTECTED_ROUTES } from 'helpers/constants/unprotectedRoutes';

const SessionTimeout = () => {
  const { formatMessage } = useFormat({ name: 'common' });
  const pageCommonTitleContent = formatMessage({ id: 'page.sessionTimout', defaultMessage: 'You have timed out!' });
  const router = useRouter();
  const { loggedIn } = useAccount();

  const loginPage = useMemo(() => {
    const lvp = router.query.lvp ? decodeURIComponent(router.query.lvp.toString()) : '';
    const pathname = `/${UNPROTECTED_ROUTES.LOGIN}`;
    const searchParams = new URLSearchParams();

    if (lvp) {
      searchParams.set('lvp', lvp);
      return `${pathname}?${searchParams.toString()}`;
    }

    return pathname;
  }, [router.query.lvp]);

  if (loggedIn) return <Redirect target="/" />;

  return (
    <>
      <MetaTitle title={pageCommonTitleContent} description={pageCommonTitleContent} />
      <div className={styles.errorContainer} data-testid="session-timeout_test-id">
        <div className={styles.messageContainer}>
          <div className={styles.errorText}>
            <Heading className="lll-text-small" tag="h1">
              {formatMessage({ id: 'page.sessionTimout', defaultMessage: 'You have timed out!' })}
            </Heading>
          </div>
          <div>
            <p className={styles.errorTextP}>
              {formatMessage({
                id: 'page.sessionTimout.desc',
                defaultMessage: 'To protect your personal information, your session has automatically timed out.',
              })}
            </p>
          </div>
          <NextLink href={loginPage}>
            <Button kind="secondary" block={true}>
              {formatMessage({ id: 'page.sessionTimout.btn', defaultMessage: 'Sign back in' })}
            </Button>
          </NextLink>
        </div>
      </div>
    </>
  );
};

export default SessionTimeout;
